const motivationalQuotes = [
    "Dream big, work hard.",
    "Believe you can and you're halfway there.",
    "Success is not final, failure is not fatal: it is the courage to continue that counts.",
    "The only way to do great work is to love what you do.",
    "Strive not to be a success, but rather to be of value.",
    "The future belongs to those who believe in the beauty of their dreams.",
    "Don't watch the clock; do what it does. Keep going.",
    "The only limit to our realization of tomorrow will be our doubts of today.",
    "Your time is limited, don't waste it living someone else's life.",
    "The best way to predict the future is to create it.",
    "It always seems impossible until it's done.",
    "The harder you work for something, the greater you'll feel when you achieve it.",
    "Success is not in what you have, but who you are.",
    "The only place where success comes before work is in the dictionary.",
    "Challenges are what make life interesting. Overcoming them is what makes life meaningful.",
    "The secret of getting ahead is getting started.",
    "Don't be afraid to give up the good to go for the great.",
    "I find that the harder I work, the more luck I seem to have.",
    "The difference between ordinary and extraordinary is that little extra.",
    "The only person you are destined to become is the person you decide to be.",
    "Opportunities don't happen. You create them.",
    "Success usually comes to those who are too busy to be looking for it.",
    "If you want to achieve greatness stop asking for permission.",
    "Things work out best for those who make the best of how things work out.",
    "To live a creative life, we must lose our fear of being wrong.",
    "If you are not willing to risk the usual you will have to settle for the ordinary.",
    "Trust because you are willing to accept the risk, not because it's safe or certain.",
    "All our dreams can come true if we have the courage to pursue them.",
    "Good things come to people who wait, but better things come to those who go out and get them.",
    "If you do what you always did, you will get what you always got.",
    "Success is walking from failure to failure with no loss of enthusiasm.",
    "Just when the caterpillar thought the world was ending, he turned into a butterfly.",
    "Successful entrepreneurs are givers and not takers of positive energy.",
    "Whenever you see a successful person you only see the public glories, never the private sacrifices to reach them.",
    "Opportunities don't happen, you create them.",
    "Try not to become a person of success, but rather try to become a person of value.",
    "Great minds discuss ideas; average minds discuss events; small minds discuss people.",
    "I have not failed. I've just found 10,000 ways that won't work.",
    "If you don't value your time, neither will others. Stop giving away your time and talents--start charging for it.",
    "A successful man is one who can lay a firm foundation with the bricks others have thrown at him.",
    "No one can make you feel inferior without your consent.",
    "The whole secret of a successful life is to find out what is one's destiny to do, and then do it.",
    "If you're going through hell, keep going.",
    "The ones who are crazy enough to think they can change the world, are the ones who do.",
    "Don't raise your voice, improve your argument.",
    "What seems to us as bitter trials are often blessings in disguise.",
    "The meaning of life is to find your gift. The purpose of life is to give it away.",
    "The distance between insanity and genius is measured only by success.",
    "When you stop chasing the wrong things, you give the right things a chance to catch you.",
    "I believe that the only courage anybody ever needs is the courage to follow your own dreams.",
    "No masterpiece was ever created by a lazy artist.",
    "Happiness is a butterfly, which when pursued, is always beyond your grasp, but which, if you will sit down quietly, may alight upon you.",
    "If you can't explain it simply, you don't understand it well enough.",
    "Blessed are those who can give without remembering and take without forgetting.",
    "Do one thing every day that scares you.",
    "What's the point of being alive if you don't at least try to do something remarkable.",
    "Life is not about finding yourself. Life is about creating yourself.",
    "Nothing in the world is more common than unsuccessful people with talent.",
    "Knowledge is being aware of what you can do. Wisdom is knowing when not to.",
    "The secret of getting ahead is getting started.",
    "Don't be afraid to give up the good to go for the great.",
    "I find that the harder I work, the more luck I seem to have.",
    "The difference between ordinary and extraordinary is that little extra.",
    "The only person you are destined to become is the person you decide to be.",
    "Opportunities don't happen, you create them.",
    "Try not to become a person of success, but rather to become a person of value.",
    "Great minds discuss ideas; average minds discuss events; small minds discuss people.",
];

export default motivationalQuotes;
